import './displayMeteo.css'
import {FaWater} from 'react-icons/fa'
import {FaWind} from 'react-icons/fa'
import React from 'react';


import yellowSun from './img/yellowSun.png'
import yellowSunAndCloud from './img/yellowSunAndCloud.png'
import yellowSunAndClouds from './img/yellowSunAndClouds.png'
import clouds from './img/clouds.png'
import rainy from './img/rainy.png'
import rain from './img/rain.png'
import pouringRain from './img/pouringRain.png'
import hail from './img/hail.png'
import snow from './img/snow.png'
import snowfall from './img/snowfall.png'
import lightning from './img/lightning.png'

const wmo = [
    [0,'Clear Sky'],
    [1,'Mainly clear'],
    [2,'Partly cloudy'],
    [3,'Overcast'],
    [45,'Fog'],
    [48,'Rime fog'],
    [51,'Light drizzle'],
    [53,'Moderate drizzle'],
    [55,'Dense drizzle'],
    [56,'Freezing drizzle'],
    [57,'Freezing drizzle'],
    [61,'Slight rain'],
    [63,'Moderate rain'],
    [65,'Heavy rain'],
    [66,'Freezing rain'],
    [67,'Freezing rain'],
    [71,'Slight snow fall'],
    [73,'Moderate snow fall'],
    [75,'Heavy snow fall'],
    [77,'Snow grains'],
    [80,'Rain showers'],
    [81,'Rain showers'],
    [82,'Rain showers'],
    [85,'Snow showers'],
    [86,'Snow showers'],
    [95,'Thunderstorm'],
    [96,'Thunderstorm'],
    [99,'Thunderstorm'],
];


export default function DisplayMeteo({data}) {

    return (
        <div className={'displayMeteo'} id={'displayMeteo'}>
            <Place data={data}></Place>
            <img id={'weatherImg'} src={getImage(data)} alt=""/>
            <Data data={data}/>
            <MoreData data={data}/>
        </div>
    )
}

function getImage(data){
    let res;
    if (data){
        switch (data.weathercode) {
            case 0:
                res = yellowSun;
                break;
            case 1:
                res = yellowSunAndClouds;
                break;
            case 2:
                res = yellowSunAndCloud;
                break;
            case 3:
            case 45:
            case 48:
                res = clouds
                break;
            case 51:
            case 53:
            case 61:
            case 55:
                res = rainy
                break;
            case 56:
            case 57:
            case 66:
            case 67:
                res = hail
                break;
            case 63:
                res = rain;
                break;
            case 65:
                res = pouringRain;
                break;
            case 71:
            case 73:
            case 77:
                res = snow;
                break;
            case 75:
                res = snowfall;
                break;
            case 80:
            case 81:
            case 82:
            case 85:
            case 86:
                res = pouringRain;
                break;
            case 95:
            case 96:
            case 99:
                res = lightning;
                break
            default:
                res = yellowSun;
                break
        }
    }
    return res;
}

function getWeatherLabel(data){
    let res;
    for (let i = 0; i<wmo.length ; i++){
        if (wmo[i][0] === data.data.weathercode){
            res = wmo[i][1];
            break;
        }
    }
    return res;
}


function Data(data){
    console.log(data);
    return (
        <div className="data">
            <div className="temperature">
                <div className="value">{Math.round(data.data.temperature_2m)}</div>
                <div className="unit">°</div>
                <div className="celcius">C</div>
            </div>
            <div className="meteo">{getWeatherLabel(data)}</div>
        </div>
    )
}

function Place(data){
    return (
        <>
            <h1 className="place">
                {data.data.place}
            </h1>
        </>
    )
}

function MoreData(data){
    return (
        <>
        <div className="moreData">
            <div className="humidity">
                <FaWater className={'icon'}/>
                <div className="humidityData">
                    <div className="value">{data.data.relativehumidity_2m}%</div>
                    <div className="label">Humidity</div>
                </div>
            </div>
            <div className="windSpeed">
                <FaWind className={'icon'}/>
                <div className="windData">
                    <div className="value">{Math.round(data.data.windspeed_10m)}km/h</div>
                    <div className="label">Wind Speed</div>

                </div>
            </div>
        </div>
        </>
    );
}
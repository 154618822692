import './App.css';
import {SearchBar} from "./searchBar/SearchBar";
import DisplayMeteo from "./displayMeteo/displayMeteo";
import React, {useState} from 'react';
import WeatherData from "./displayMeteo/WeatherData";

function App() {
    const [data, updateData] = useState(new WeatherData('',0,0,0,0));

    function updateDataAfterApiCall(data){
        updateData(data)
    }

    function errorOnFetchCallback(){
        document.getElementById('errorMsg').style.display = "flex"
    }
    function successOnFetchCallback(){
        document.getElementById('errorMsg').style.display = "none"
    };


    return (
        <div className="App" id={'App'}>
            <span id={'errorMsg'}> Unknown city </span>
            <SearchBar loadedDataCallback={updateDataAfterApiCall}
                       errorOnFetchCallback={errorOnFetchCallback}
                       successOnFetchCallback={successOnFetchCallback} />
            <DisplayMeteo data={data}/>
        </div>
  );
}

export default App;

import './SearchBar.css';

import {FaMagnifyingGlass, FaLocationDot} from 'react-icons/fa6'
import {useEffect, useState} from "react";
import React  from 'react';


const cities = ['Paris', 'London', 'Tokyo', 'New York', 'Rabat'];

export function SearchBar({loadedDataCallback, errorOnFetchCallback, successOnFetchCallback} ){
    const [indexCurrentCity, setIndex] = useState(0);
    const [searchedCity, updateSearchedCity] = useState('');


/*    let callDoneForSearchOnEnter = false;
    window.addEventListener('keydown',event => {
        if (event.key === 'Enter'  && !callDoneForSearchOnEnter){
            callDoneForSearchOnEnter = true;
            onSearch()
        }
    })*/

    function onSearch() {
        document.documentElement.style.cursor = "progress";
        if(searchedCity !== ''){
            fetch(
                'https://api.api-ninjas.com/v1/geocoding?city='+searchedCity,
                {headers: new Headers({
                        'X-Api-Key':'qJZrqcf7jWFxKz5mhXOibQ==UchP2mXUlOWxryO5'
                    })}
                )
                .then(res => res.json())
                .then( data => {
                    return fetch(`https://api.open-meteo.com/v1/forecast?latitude=${data[0].latitude}&longitude=${data[0].longitude}&current=temperature_2m,relativehumidity_2m,is_day,weathercode,windspeed_10m`);
                })
                .then(res => res.json())
                .then( data => {
                    data.current.place = searchedCity;
                    console.log(data);
                    loadedDataCallback(data.current);
                    successOnFetchCallback();
                    onSearchStyle();

                    document.documentElement.style.cursor = "unset";
                })
                .catch(err=> {
                    console.log(err.message);
                    errorOnFetchCallback();
                    document.documentElement.style.cursor = "unset";
                });
        }
    }


    function onSearchStyle() {
        document.getElementById('App').style.height = "70vh";
        document.getElementById('searchBar').style.paddingTop = "8px";
        document.getElementById('displayMeteo').style.visibility = "visible";
        document.getElementById('displayMeteo').style.opacity = "100%";
    }

    function onChangeCity (event){
        updateButtonStyle(event.target.value);
        updateSearchedCity(event.target.value);
    }

    function updateButtonStyle(value) {
        let button = document.getElementById('searchButton');
        if (value.length !== 0){
            button.style.backgroundColor = "#87f1ff";
            button.style.cursor = "pointer";
        }
        else{
            button.style.backgroundColor = "#dff6ff";
            button.style.cursor = "not-allowed;";
        }
    }

    useEffect(()=>{
        const timer = () => {
            setIndex(prevIndex => {
                if(prevIndex === cities.length - 1){
                    return 0;
                }
                return prevIndex + 1;
            })
        };
        setInterval(timer, 4000);

        //cleanup function in order clear the interval timer
        //when the component unmounts
        return () => { clearInterval(timer); }
    }, []);




    return (
        <>
            <div className={'Searchbar'} id={'searchBar'}>
                <FaLocationDot className={'locationDot'}/>
                <input
                    type="text"
                    value={searchedCity}
                    id={'inputCity'}
                    placeholder={cities[indexCurrentCity]}
                    onChange={onChangeCity}
                />
                <button onClick={onSearch} type={'submit'} id={'searchButton'}>
                    <FaMagnifyingGlass className={'faSearch'} />
                </button>
            </div>
        </>
    )
}


